<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <login-form />
    </div>
  </div>
</template>

<script>
import LoginForm from '@/views/login/LoginForm'

export default {
  name: 'LoginPage',
  components: { LoginForm }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
